import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { isDesktop, isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { SectionWrapWithPaddingY } from '../styles/styledComponents';
import quote from '../assets/quote.jpg';
import imgAnnetBlack from '../assets/img_annet_black.jpg';
import imgKristyBeach from '../assets/img_kristy_beach.jpg';
import imgLisaYes from '../assets/img_lisa_yes.jpg';
import { useTranslation } from 'react-i18next';

const StyledWrap = styled('div')`
  margin-top: 32px;
  margin-bottom: 0;

  margin-left: -26px;
  margin-right: -26px;

  .alice-carousel {
    &__dots-item:not(.__custom) {
      width: ${() => (isMobile ? '8px' : '16px')};
      height: ${() => (isMobile ? '8px' : '16px')};
      background-color: #ffd8e8;

      &:hover,
      &.__active {
        background-color: #f43686;
      }
    }

    &__dots {
      margin-bottom: 0;
      margin-top: ${() => (isMobile ? '-26px' : '0')};

      &-item:not(.__custom):not(:last-child) {
        margin-right: ${() => (isMobile ? '4px' : '8px')};
      }
    }

    &__prev-btn,
    &__next-btn {
      position: absolute;
      overflow: hidden;
      width: 52px;
      height: 52px;
      top: 120px;
      padding: 0;

      ${isMobile &&
      css`
        display: none;
      `};
    }

    &__prev-btn {
      left: 0;
    }

    &__next-btn {
      right: 0;
    }

    &__prev-btn-item,
    &__next-btn-item {
      display: flex;
      cursor: pointer;
      padding: 0;
      margin: 0;
      color: #fff;
      background-color: #f43686;
      font-size: 30px;
      font-weight: 300;
      border-radius: 50%;
      width: 52px;
      height: 52px;
      text-align: center;
      align-items: center;
      justify-content: center;

      &:hover {
        color: #fff;
      }

      &.__inactive {
        opacity: 0;
      }
    }
  }
`;

const StyledCard = styled('div')`
  padding: ${() => (isMobile ? '24px 12px' : '48px 24px')};
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(57, 57, 57, 0.25);
  border-radius: 24px;
  position: relative;
  display: flex;
  align-items: ${() => (isMobile ? 'flex-start' : 'center')};
`;

const StyledInnerWrap = styled('div')``;

const StyledQuote = styled('div')`
  background: url(${quote}) no-repeat center center;
  background-size: contain;
  width: ${() => (isMobile ? '56px' : '98px')};
  height: ${() => (isMobile ? '40px' : '68px')};
  position: absolute;
  top: ${() => (isMobile ? '-16px' : '-28px')};
  right: ${() => (isMobile ? '12px' : '32px')};
`;

const StyledImage = styled('div')<{ $bg: string }>`
  margin-right: ${() => (isMobile ? '16px' : '24px')};
  flex: none;
  width: ${() => (isMobile ? '60px' : '120px')};
  height: ${() => (isMobile ? '60px' : '120px')};
  overflow: hidden;
  border-radius: 50%;
  background-image: url(${({ $bg }) => $bg});
  background-size: cover;
`;

const StyledName = styled('p')`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  align-items: left;
  color: #2d3436;
  margin: 0;
  margin-bottom: ${() => (isMobile ? '12px' : '16px')};
`;

const StyledText = styled('p')`
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: #2d3436;
`;

const StyledChunkWrap = styled('div')`
  display: flex;
  flex-wrap: nowrap;

  padding: 30px 26px;
  overflow: hidden;
`;

export default function Carousel() {
  const { t } = useTranslation();

  const array = [
    {
      img: imgAnnetBlack,
      name: t('shoutout.carousel.one.name'),
      text: t('shoutout.carousel.one.text')
    },
    {
      img: imgKristyBeach,
      name: t('shoutout.carousel.two.name'),
      text: t('shoutout.carousel.two.text')
    },
    {
      img: imgLisaYes,
      name: t('shoutout.carousel.three.name'),
      text: t('shoutout.carousel.three.text')
    }
  ];

  const items = array.map((d, idx) => (
    <StyledChunkWrap key={idx}>
      <CarouselCard img={d.img} name={d.name} text={d.text} />
    </StyledChunkWrap>
  ));

  return (
    <SectionWrapWithPaddingY>
      <StyledWrap>
        <AliceCarousel
          items={items}
          disableButtonsControls={isMobile}
          disableDotsControls={isDesktop}
          infinite={isDesktop}
          responsive={
            isDesktop
              ? {
                  0: {
                    items: 2
                  }
                }
              : undefined
          }
        />
      </StyledWrap>
    </SectionWrapWithPaddingY>
  );
}

function CarouselCard({ img, name, text }: { img: string; name: string; text: string }) {
  return (
    <StyledCard>
      <StyledQuote />
      <StyledImage $bg={img} />
      <StyledInnerWrap>
        <StyledName>{name}</StyledName>
        <StyledText>{text}</StyledText>
      </StyledInnerWrap>
    </StyledCard>
  );
}
