import i18n from '../../i18n/config';
import { ValidatedStringValue } from '../values/ValidatedStringValue';
import { ValidationResult } from './result';

/* 
  const emailRegexOLD =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
   */

/* 
  "rules": {
    "no-control-regex": 0
  } 
 
  https://www.w3resource.com/javascript/form/email-validation.php RFC 2822 standard email validation
  */

const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export async function validateEmail(email?: string) {
  if (!email || !emailRegex.test(email)) return ValidationResult.InvalidFormat;

  return ValidationResult.Ok;
}

const EmailValidationMessages = {
  [ValidationResult.Ok]: () => '',
  [ValidationResult.Required]: () => i18n.t('validation.required'),
  [ValidationResult.InvalidFormat]: () => i18n.t('validation.email.invalid_format'),
  [ValidationResult.Taken]: () => i18n.t('validation.email.taken'),
  [ValidationResult.Disposable]: () => i18n.t('validation.email.disposable')
};

export function createEmailValidator(defaultValue: string = '') {
  return new ValidatedStringValue(
    async (value: string) => {
      if (!emailRegex.test(value)) return ValidationResult.InvalidFormat;
      return ValidationResult.Ok;
    },
    EmailValidationMessages,
    defaultValue
  );
}

export function createEmailRequiredValidator(defaultValue: string = '') {
  return new ValidatedStringValue(
    async (value: string) => {
      if (!value) return ValidationResult.Required;
      if (!emailRegex.test(value)) return ValidationResult.InvalidFormat;
      return ValidationResult.Ok;
    },
    EmailValidationMessages,
    defaultValue
  );
}
