import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { SectionWrapWithPaddingY } from '../styles/styledComponents';
import { ReactComponent as LogoDesktop } from '../../../../assets/logo/fanscity_logo_desktop.svg';
import { ReactComponent as LogoMobile } from '../../../../assets/logo/fanscity_logo_mobile.svg';

const StyledWrap = styled('div')`
  background-color: ${() => (isMobile ? 'transparent' : '#FFFFFF')};
  height: ${() => (isMobile ? '80px' : '96px')};
  padding: ${() => (isMobile ? '0' : '23px')} 0;

  ${() =>
    isMobile &&
    css`
      z-index: 2;
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      width: 100%;
    `};
`;

export default function Header() {
  return (
    <StyledWrap>
      <SectionWrapWithPaddingY>
        <BrowserView>
          <LogoDesktop />
        </BrowserView>

        <MobileView>
          <LogoMobile />
        </MobileView>
      </SectionWrapWithPaddingY>
    </StyledWrap>
  );
}
