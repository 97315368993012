/**
 * A helper for making server requests (or any other async work).
 * Provides a convenient way to get the execution state and the execution result/error.
 */
import { observable, action, makeObservable } from 'mobx';

export default class Task<TRet> {
  requestId = 0;
  /** There's an async process currently running in this instance of AsyncRequest */
  @observable running = false;
  /** Last return value of async process, does not clear if subsequent process errors out. */
  @observable.ref data?: TRet;
  /** Timestamp of last successful process execution, does not clear if subsequent process errors out. */
  @observable lastSuccessTimestamp?: number;

  /** Error of the last execution if it failed. Clears on subsequent successful run. */
  @observable.ref error?: Error;
  /** Timestamp of the last execution if it failed. Clears on subsequent successful run. */
  @observable errorTimestamp?: number;

  get loadedOnce() {
    return !!(this.lastSuccessTimestamp || this.errorTimestamp);
  }

  constructor(private process: () => Promise<TRet>) {
    makeObservable(this);
  }

  @action private setSuccess(result: TRet) {
    this.data = result;
    this.running = false;
    this.lastSuccessTimestamp = Date.now();

    this.error = undefined;
    this.errorTimestamp = undefined;
  }

  @action private setError(err: Error) {
    console.error(err);
    let error = err;
    if (!(error instanceof Error)) {
      error = new Error(String(err));
    }
    this.error = error;
    this.errorTimestamp = Date.now();
    this.running = false;
  }

  /**
   * Starts the asynchronous request
   * @param process - request execution function
   * @param force - force execution even if another request is already running (old request result will be discarded)
   */
  @action.bound
  async load(force = false) {
    if (!force && this.running) return;
    this.running = true;
    const requestId = ++this.requestId;
    try {
      const result = await this.process();
      // skipping result because other forced request was called while we were waiting for response
      if (requestId !== this.requestId) return;
      this.setSuccess(result);
    } catch (err) {
      this.setError(err as Error);
    }
  }
}
