import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';

export const SectionWrapWithPaddingY = styled('div')<{ $aBitOfZindexPls?: boolean }>`
  padding-left: ${() => (isMobile ? '20px' : '112px')};
  padding-right: ${() => (isMobile ? '20px' : '112px')};
  overflow: hidden;

  ${({ $aBitOfZindexPls }) =>
    $aBitOfZindexPls &&
    css`
      position: relative;
      z-index: 1;
    `}
`;
