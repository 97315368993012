import { Navigate, Route, Routes } from 'react-router-dom';
import LanguageChecker from '../i18n/LanguageChecker';
import HomePage from '../pages/HomePage';
import ShououtEmbedPage from '../pages/landings/shoutout/ShououtEmbedPage';
import ShoutoutPage from '../pages/landings/shoutout/ShoutoutPage';
import ShoutoutShortPage from '../pages/landings/shoutout/ShoutoutShortPage';
import ShoutoutThirdPage from '../pages/landings/shoutout/ShoutoutThirdPage';
import ModelPage from '../pages/model/ModelPage';

export default function MainContent() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />

      <Route path="landing/*">
        <Route path="shoutout/*">
          <Route path="1/*">
            <Route
              path=":lang"
              element={
                <LanguageChecker>
                  <ShoutoutPage />
                </LanguageChecker>
              }
            />

            <Route path="*" element={<Navigate to="en" />} />
          </Route>

          <Route path="2/*">
            <Route
              path=":lang"
              element={
                <LanguageChecker>
                  <ShoutoutShortPage />
                </LanguageChecker>
              }
            />

            <Route path="*" element={<Navigate to="en" />} />
          </Route>

          <Route path="3/*">
            <Route
              path=":lang"
              element={
                <LanguageChecker>
                  <ShoutoutThirdPage />
                </LanguageChecker>
              }
            />

            <Route path="*" element={<Navigate to="en" />} />
          </Route>

          <Route path="embed/*">
            <Route
              path=":lang"
              element={
                <LanguageChecker>
                  <ShououtEmbedPage />
                </LanguageChecker>
              }
            />

            <Route path="*" element={<Navigate to="en" />} />
          </Route>

          <Route path="*" element={<Navigate to="/" />} />
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Route>

      <Route path="/:modelId" element={<ModelPage />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
