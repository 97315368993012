import { action, observable, runInAction, makeObservable } from 'mobx';
import { IGenericValue, NormalValueBase } from './value';
import { debounce } from 'lodash';
import { ValidationResult } from '../validators/result';

const validatorDebounce = 200;
export interface IStringValue extends IGenericValue<string> {}

export class ValidatedStringValue<T, A = void> extends NormalValueBase implements IStringValue {
  @observable value: string = '';

  constructor(
    private validator: (value: string, additionalData?: A) => Promise<keyof T>,
    private messages: {
      [P in keyof T]: () => string;
    },
    private defaultValue: string = ''
  ) {
    super();
    makeObservable(this);
    runInAction(() => {
      this.value = defaultValue;
      this._isDirty = false;
      this._isValid = false;
      this._isChecked = false;
    });
  }

  @action.bound set(text: string, additionalData?: A) {
    if (text !== this.value) {
      this._isDirty = true;
    }
    this._isValid = false;
    this._isChecked = false;
    this._errorMessage = '';
    this.value = text;
    this.revalidate(additionalData);
  }

  revalidate = debounce(async (additionalData?: A) => {
    const { value } = this;
    // ignore validation for value matching default values
    // if (this.defaultValue && value === this.defaultValue) return;
    try {
      const result = await this.validator(value, additionalData);
      if (value !== this.value) return;
      runInAction(() => {
        this._isChecked = true;
        if (result === ValidationResult.Ok) {
          this._isValid = true;
        } else {
          this._isValid = false;
          this._errorMessage = this.messages[result]();
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, validatorDebounce);
}
