import { observable, action, makeObservable } from 'mobx';
export interface IValue {
  errorMessage?: string;
  isValid: boolean;
  isInvalid: boolean;
  isDirty: boolean;
  isChecked: boolean;
  resetDirty: (isDirty?: boolean) => void;
}

export interface IGenericValue<T> extends IValue {
  set: (value: T) => void;
  setFocused: () => void;
  setBlurred: () => void;
  value: T;
  isFocused: boolean;
}

/**
 * Helper class for no-validation fields
 */
export class NormalValueBase implements IValue {
  constructor() {
    makeObservable(this);
  }

  @action.bound setFocused() {
    this._isFocused = true;
    this._isDirty = true;
  }

  @action.bound setBlurred() {
    this._isFocused = false;
  }

  @action.bound resetDirty(isDirty = false) {
    this._isDirty = isDirty;
  }

  get isValid() {
    return this._isValid;
  }
  get isInvalid() {
    return this.isChecked && !this.isValid;
  }
  get isChecked() {
    return this._isChecked;
  }
  get errorMessage() {
    return this._errorMessage;
  }
  get isFocused() {
    return this._isFocused;
  }
  get isDirty() {
    return this._isDirty;
  }
  @observable protected _errorMessage?: string;
  @observable protected _isFocused = false;
  @observable protected _isDirty = false;
  @observable protected _isValid = true;
  @observable protected _isChecked = true;
}

export class NormalValue extends NormalValueBase {
  constructor() {
    super();
    makeObservable(this);
  }

  @observable value: string = '';

  @action.bound set(text: string) {
    if (text !== this.value) {
      this._isDirty = true;
    }
    this.value = text;
  }
}

export class NormalValueArray<T = string> extends NormalValueBase {
  constructor() {
    super();
    makeObservable(this);
  }

  @observable.ref value?: T[];
  @action.bound set(values: T[]) {
    if (this.value !== undefined) {
      this._isDirty = true;
    }
    this.value = values;
  }
}
