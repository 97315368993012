import { Amplify } from 'aws-amplify';
import { createRoot } from 'react-dom/client';
import config from './config';
import Root from './ui/Root';
import { getApiUrl, getUserPoolClientId, getUserPoolId } from './utils/env';

import './i18n/config';

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: getUserPoolId(),
    userPoolWebClientId: getUserPoolClientId()
  },
  API: {
    endpoints: [
      {
        name: 'api',
        endpoint: getApiUrl(),
        region: config.apiGateway.REGION
      }
    ]
  }
});

/**
 * google analytics
 */
/*
function initGTAG() {
  const id = 'UA-000000-0';

  window.dataLayer = window.dataLayer || [];

  function gtag(_a: any) {
    window.dataLayer.push(_a);
  }

  window.gtag = gtag;

  (function (w, d, s, l, i) {
    //@ts-ignore not important enough to waste time typing this
    w[l] = w[l] || [];
    //@ts-ignore not important enough to waste time typing this
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    //@ts-ignore not important enough to waste time typing this
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== 'dataLayer' ? '&l=' + l : '';
    //@ts-ignore not important enough to waste time typing this
    j.async = true;
    //@ts-ignore not important enough to waste time typing this
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    //@ts-ignore not important enough to waste time typing this
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', id);
} 

initGTAG(); 
*/

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<Root />);
