import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import React from 'react';
import { withRouter, WithRouter } from '../../ui/components/ComponentWithRouterProp';
import Task from '../../utils/Task';
import { getSinglePublicUser, listLinks } from '../../api/APIClient';
import { Link, PublicUser, LinksResp } from 'fanpoint-types';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { parse } from 'query-string';
import { isMobile } from 'react-device-detect';
import ButtonPlaceholder from './ButtonPlaceholder';
import LinksPlaceholder from './LinksPlaceholder';
import LoadingPlaceholder from './LoadingPlaceholder';
import HeaderPlaceholder from './HeaderPlaceholder';

const StyledContainer = styled('div')`
  width: 100%;
  max-width: 68rem;
  background: #ffffff;
  border-width: ${() => (isMobile ? '0' : '0 1px')};
  border-style: solid;
  border-color: #f1ebf5;
  box-shadow: 1px 0 0 #f1ebf5;
  margin: 0 auto;
  padding-bottom: 24px;
`;

@observer
class ModelPage extends React.Component<WithRouter> {
  constructor(props: any) {
    super(props);

    makeObservable(this);
  }

  @observable user?: PublicUser;
  @observable linksAll: Link[] = [];
  @observable collapsed = true;

  get modelId() {
    return this.props.router.params.modelId || '';
  }

  @computed
  get links() {
    return this.linksAll.filter((link: Link) => !link.adult && !link.hidden);
  }

  @computed
  get linksNsfw() {
    return this.linksAll.filter(link => link.adult && !link.hidden);
  }

  userTask = new Task<IResponse<PublicUser>>(async () => {
    const resp = await getSinglePublicUser(this.modelId);

    if (resp.payload) {
      if (resp.payload.nickname && resp.payload.nickname !== this.modelId) {
        this.props.router.navigate(`/${resp.payload.nickname}`, { replace: true });
      }

      runInAction(() => {
        this.user = resp.payload;
        this.linksTask.load();
      });
    }

    return resp;
  });

  linksTask = new Task<IResponse<LinksResp>>(async () => {
    const resp = await listLinks(this.user!.id);

    if (resp.payload?.results) {
      this.linksAll = resp.payload!.results.slice().sort((a, b) => a.sortNumber - b.sortNumber);
    }

    return resp;
  });

  @action.bound
  handleLocationSearch() {
    const parsed = parse(window.location.search);
    const nsfw = !!parsed['nsfw'];

    if (nsfw) {
      this.handleCollapse();
    }
  }

  @action.bound
  handleCollapse() {
    this.collapsed = !this.collapsed;
  }

  componentDidMount() {
    this.userTask.load();
    this.handleLocationSearch();
  }

  render() {
    return (
      <StyledContainer>
        {this.userTask.running ? (
          <LoadingPlaceholder />
        ) : (
          <>
            <HeaderPlaceholder user={this.user} />

            <Box px={2}>
              {this.linksTask.running ? (
                <LoadingPlaceholder />
              ) : (
                <>
                  {this.links.length ? <LinksPlaceholder links={this.links} /> : null}

                  {this.linksNsfw.length > 0 ? (
                    <>
                      <ButtonPlaceholder
                        handleClick={this.handleCollapse}
                        collapsed={this.collapsed}
                      />

                      {this.collapsed ? null : <LinksPlaceholder links={this.linksNsfw} />}
                    </>
                  ) : null}
                </>
              )}
            </Box>
          </>
        )}
      </StyledContainer>
    );
  }
}

export default withRouter(ModelPage);
