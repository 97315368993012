import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { SectionWrapWithPaddingY } from '../styles/styledComponents';

const StyledWrap = styled('div')`
  margin-top: ${() => (isMobile ? '72px' : '100px')};
  margin-bottom: 0;

  &:after {
    content: '';
    clear: both;
    display: block;
  }
`;

const StyledWrapCard = styled('div')`
  border-radius: 16px;
  // margin-top: ${() => (isMobile ? '72px' : '100px')};
  margin-top: 32px;
  padding: ${() => (isMobile ? '40px 20px' : '80px 20px')};

  ${!isMobile &&
  css`
    float: left;
    width: calc(50% - 36px);
  `}

  &:nth-child(odd) {
    background: #f43686;
    color: #ffffff;

    ${!isMobile &&
    css`
      margin-right: 36px;

      &:not(:first-child) {
        margin-top: -50px;
      }
    `}
  }

  &:nth-child(even) {
    background: #ffffff;
    color: #f43686;
    box-shadow: 0 5px 20px rgba(57, 57, 57, 0.25);

    ${!isMobile &&
    css`
      margin-left: 36px;
      margin-top: 250px;
    `}
  }
`;

const StyledTitle = styled('div')`
  margin-bottom: 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: ${() => (isMobile ? '20px' : '32px')};
  line-height: ${() => (isMobile ? '24px' : '38px')};
  text-align: center;
`;

const StyledDescription = styled('div')`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: ${() => (isMobile ? '16px' : '20px')};
  line-height: ${() => (isMobile ? '21px' : '24px')};
  text-align: center;
`;

export default function Cards(o: {data: {title: string | JSX.Element, description: string}[]}) {
  return (
    <SectionWrapWithPaddingY>
      <StyledWrap>
        {o.data.map(card => (
          <Card title={card.title} description={card.description} />
        ))}
      </StyledWrap>
    </SectionWrapWithPaddingY>
  );
}

function Card({
  title,
  description
}: {
  title: JSX.Element | string;
  description: JSX.Element | string;
}) {
  return (
    <StyledWrapCard>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
    </StyledWrapCard>
  );
}
