import { Markup } from 'interweave';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SectionWrapWithPaddingY } from '../styles/styledComponents';

const StyledWrap = styled('div')`
  margin-top: 72px;
  margin-bottom: 0;
`;

const StyledTitle = styled('h2')`
  margin-top: ${() => (isMobile ? '72px' : '100px')};
  margin-bottom: ${() => (isMobile ? '16px' : '32px')};
  margin-left: 0;
  margin-right: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: ${() => (isMobile ? '46px' : '96px')};
  line-height: ${() => (isMobile ? '46px' : '104px')};
  text-align: left;
  letter-spacing: -2px;
  color: #2d3436;
  text-shadow: 0px 4px 0px #ffffff;

  & .pink {
    color: #f43686;
  }
`;

export default function Description() {
  const { t } = useTranslation();
  const title = (
    <Markup content={isMobile ? t('shoutout.description.mob') : t('shoutout.description.desk')} />
  );

  return (
    <SectionWrapWithPaddingY>
      <StyledWrap>
        <StyledTitle>{title}</StyledTitle>
      </StyledWrap>
    </SectionWrapWithPaddingY>
  );
}
