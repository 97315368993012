import { AdultResourceName } from 'fanpoint-types';

export const customResourceName = {
  [AdultResourceName.clips4sale]: 'Clips4Sale',
  [AdultResourceName.fancentro]: 'FanCentro',
  [AdultResourceName.ismygirl]: 'IsMyGirl',
  [AdultResourceName.iwantclips]: 'IWantClips',
  [AdultResourceName.justforfans]: 'JustForFans',
  [AdultResourceName.loverfans]: 'LoverFans',
  [AdultResourceName.loyalfans]: 'LoyalFans',
  [AdultResourceName.manyvids]: 'ManyVids',
  [AdultResourceName.modelcentro]: 'ModelCentro',
  [AdultResourceName.modelhub]: 'ModelHub',
  [AdultResourceName.onlyfans]: 'OnlyFans',
  [AdultResourceName.pornhub]: 'PornHub',
  [AdultResourceName.twitter]: 'Twitter',
  [AdultResourceName.xhamster]: 'xHamster',
  [AdultResourceName.xvideos]: 'xVideos',
  [AdultResourceName.fansly]: 'Fansly'
} as { [K in AdultResourceName]: string };

export const customResourceLink = {
  [AdultResourceName.clips4sale]: 'https://www.clips4sale.com/',
  [AdultResourceName.fancentro]: 'https://fancentro.com/',
  [AdultResourceName.ismygirl]: 'https://ismygirl.com/',
  [AdultResourceName.iwantclips]: 'https://iwantclips.com/',
  [AdultResourceName.justforfans]: 'https://justfor.fans/',
  [AdultResourceName.loverfans]: 'https://loverfans.com/',
  [AdultResourceName.loyalfans]: 'https://www.loyalfans.com/',
  [AdultResourceName.manyvids]: 'https://www.manyvids.com/',
  [AdultResourceName.modelcentro]: 'https://modelcentro.com/',
  [AdultResourceName.modelhub]: 'https://www.modelhub.com/',
  [AdultResourceName.onlyfans]: 'https://onlyfans.com/',
  [AdultResourceName.pornhub]: 'https://www.pornhub.com/',
  [AdultResourceName.twitter]: 'https://twitter.com/',
  [AdultResourceName.xhamster]: 'https://xhamster.com/',
  [AdultResourceName.xvideos]: 'https://www.xvideos.com/',
  [AdultResourceName.fansly]: 'https://www.fansly.com/'
} as { [K in AdultResourceName]: string };

export const customResourceLinkText = {
  [AdultResourceName.clips4sale]: 'Clips4Sale.com',
  [AdultResourceName.fancentro]: 'FanCentro.com',
  [AdultResourceName.ismygirl]: 'IsMyGirl.com',
  [AdultResourceName.iwantclips]: 'IWantClips.com',
  [AdultResourceName.justforfans]: 'JustFor.Fans',
  [AdultResourceName.loverfans]: 'LoverFans.com',
  [AdultResourceName.loyalfans]: 'LoyalFans.com',
  [AdultResourceName.manyvids]: 'ManyVids.com',
  [AdultResourceName.modelcentro]: 'ModelCentro.com',
  [AdultResourceName.modelhub]: 'ModelHub.com',
  [AdultResourceName.onlyfans]: 'OnlyFans.com',
  [AdultResourceName.pornhub]: 'PornHub.com',
  [AdultResourceName.twitter]: 'Twitter.com',
  [AdultResourceName.xhamster]: 'xHamster.com',
  [AdultResourceName.xvideos]: 'xVideos.com',
  [AdultResourceName.fansly]: 'fansly.com'
} as { [K in AdultResourceName]: string };
