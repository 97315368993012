import Box from '@mui/material/Box';
import LoadingSpinner from '../../ui/components/LoadingSpinner';

const LoadingPlaceholder = () => {
  return (
    <Box py={4}>
      <LoadingSpinner />
    </Box>
  );
};

export default LoadingPlaceholder;
