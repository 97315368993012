import React from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '@mui/material/Icon';
import styled, { css } from 'styled-components';
import TextField from '@mui/material/TextField';
import { WithTranslation, withTranslation } from 'react-i18next';

const StyledMuiTextField = styled(TextField)<{ $largeSize?: boolean; $largeMargin?: boolean }>`
  /* incase if need to hide arrows from type=number field
  & .MuiInputBase-input[type='number'] {
    appearance: textfield;
  }

  & .MuiInputBase-input[type='number']::-webkit-outer-spin-button,
  & .MuiInputBase-input[type='number']::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
  */

  ${props =>
    props.$largeSize &&
    css`
      & .MuiInputLabel-root {
        font-size: 1.6rem;
      }

      & .MuiInputBase-root {
        font-size: 1.6rem;
      }
    `}

  ${props =>
    props.$largeMargin &&
    css`
      margin: ${props => props.theme.spacing(5)} 0 0;
    `}
`;

export type CustomTextFieldProps = TextFieldProps & {
  'data-test-id'?: string;
  showValidIcon?: boolean;
  startAdornmentIcon?: React.ReactElement;
  endAdornmentIcon?: React.ReactElement;
  readOnlyInput?: boolean;
  $largeSize?: boolean;
  $largeMargin?: boolean;
};

@observer
class CustomTextField extends React.Component<CustomTextFieldProps & WithTranslation> {
  constructor(props: any) {
    super(props);
    makeObservable(this);
  }
  @observable touched: boolean = false;

  get isError() {
    if (this.props.error) {
      return true;
    }

    if (this.props.required) {
      return this.touched && this.props.value === '' ? true : false;
    } else {
      return false;
    }
  }

  get helperText() {
    function isEmptyString(elem: any): boolean {
      return elem
        ? typeof elem === 'string' || elem instanceof String
          ? !elem.trim().length
          : false
        : false;
    }

    if (this.isError && this.props.required && isEmptyString(this.props.helperText)) {
      return this.props.t('validation.required');
    }

    if (this.props.helperText) {
      return this.props.helperText;
    }

    return '';
  }

  getInputEndAdornment() {
    return (
      <InputAdornment position={'end'} data-test-id="input-success-icon">
        <Icon>{/* <Check style={{ color: this.app.theme.palette.success.main }} /> */}</Icon>
      </InputAdornment>
    );
  }

  @action.bound
  onBlur(ev: any) {
    this.touched = true;

    if (this.props.onBlur) {
      this.props.onBlur(ev);
    }
  }

  render() {
    const {
      onBlur,
      startAdornmentIcon,
      endAdornmentIcon,
      showValidIcon,
      t,
      i18n,
      tReady,
      readOnlyInput,
      ...props
    } = this.props;

    return (
      <StyledMuiTextField
        variant="outlined"
        margin="normal"
        fullWidth
        InputProps={{
          readOnly: readOnlyInput,
          startAdornment: startAdornmentIcon ? startAdornmentIcon : '',
          endAdornment: endAdornmentIcon
            ? endAdornmentIcon
            : this.props.showValidIcon && this.props.value && !this.props.error
            ? this.getInputEndAdornment()
            : ''
        }}
        {...props}
        onBlur={this.onBlur}
        error={this.isError}
        helperText={this.helperText}
      />
    );
  }
}

export default withTranslation()(CustomTextField);
