import { PublicUser } from 'fanpoint-types';
import styled from 'styled-components';
import { optimizePicture } from '../../utils/media';
import CustomAvatar from '../../ui/components/custom/CustomAvatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const HeaderWrap = styled('div')`
  padding-bottom: 3.2rem;
`;

export const HeaderContainer = styled('div')<{ $imageUrl?: string }>`
  background-image: url(${props =>
    props.$imageUrl &&
    optimizePicture({
      initialUrl: props.$imageUrl,
      width: 680,
      fit: 'cover'
    })});
  background-color: ${props => props.theme.colors.pink.light};
  background-position: center;
  background-size: cover;
  height: ${props => props.theme.spacing(16)};
  position: relative;
  margin-bottom: ${props => props.theme.spacing(6)};
`;

const StyledName = styled('p')`
  margin: 0;
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 400;
`;

const StyledNickName = styled('p')`
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
  color: ${p => p.theme.palette.primary.main};
`;

const StyledDescription = styled('p')`
  margin: 1.6rem 0 0;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
`;

const HeaderPlaceholder = ({ user }: { user?: PublicUser }) => {
  return (
    <HeaderWrap>
      <HeaderContainer $imageUrl={user?.cover}>
        <CustomAvatar avatar={user?.avatar} transformLeft />
      </HeaderContainer>

      <Box px={2}>
        <Stack direction="column">
          {user?.displayName ? <StyledName>{user?.displayName}</StyledName> : null}
          {user?.nickname ? <StyledNickName>{`@${user?.nickname}`}</StyledNickName> : null}
          {user?.description ? (
            <StyledDescription>{user.description}</StyledDescription>
          ) : null}
        </Stack>
      </Box>
    </HeaderWrap>
  );
};

export default HeaderPlaceholder;
