import { createGlobalStyle } from 'styled-components';
import { minWidthForPage } from './themes';

export const GlobalStyle = createGlobalStyle`
  html {  
    font-size: 62.5%; /* now we can use rem units like font-size: 1.4rem == 14px */
  }

  body {
    min-width: ${minWidthForPage}px;
    overscroll-behavior: none;
  }

  #root{
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    min-height: 100vh;
  }

  /* loading icon animation */
  @keyframes spin { 
    to { 
      transform: rotate(360deg);
    }
  }
  .spin > path{
    animation: 0.5s linear 0s infinite normal none running spin;
    transform-origin: center center;
  }
`;
