// import { red } from '@mui/material/colors';
import { createTheme, PaletteColor, ThemeOptions } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/styles' {
  interface Theme {
    colors: {
      black: PaletteColor;
      blue: PaletteColor;
      grey: PaletteColor;
      charcoal: PaletteColor;
      green: PaletteColor;
      orange: PaletteColor;
      pink: PaletteColor;
      red: PaletteColor;
      purple: PaletteColor;
      white: PaletteColor;
    };

    gradients: {
      gr1: string;
      gr2: string;
      gr3: string;
      gr4: string;
      gr5: string;
      gr6: string;
      gr7: string;
    };
  }

  interface ThemeOptions {
    colors: {
      black: PaletteColor;
      blue: PaletteColor;
      charcoal: PaletteColor;
      grey: PaletteColor;
      green: PaletteColor;
      orange: PaletteColor;
      pink: PaletteColor;
      purple: PaletteColor;
      white: PaletteColor;
    };

    gradients: {
      gr1: string;
      gr2: string;
      gr3: string;
      gr4: string;
      gr5: string;
      gr6: string;
      gr7: string;
    };
  }
}

const colorsLight = {
  black: {
    light: '#000000',
    main: '#000000',
    dark: '#000000',
    contrastText: '#FFFFFF'
  },
  blue: {
    light: '#007AFF',
    main: '#007AFF',
    dark: '#007AFF',
    contrastText: '#FFFFFF'
  },
  charcoal: {
    light: '#2F2A32',
    main: '#2F2A32',
    dark: '#2F2A32',
    contrastText: '#FFFFFF'
  },
  grey: {
    light: '#AEA8B2',
    main: '#A4A4A4',
    dark: '#605866',
    contrastText: '#000000'
  },
  green: {
    light: '#D3F5DC',
    main: '#23CB52',
    dark: '#23CB52',
    contrastText: '#000000'
  },
  orange: {
    light: '#FFE8D3',
    main: '#FF8D23',
    dark: '#FF8D23',
    contrastText: '#000000'
  },
  pink: {
    light: '#FFD8E8',
    main: '#F43686',
    dark: '#F43686',
    contrastText: '#000000'
  },
  red: {
    light: '#FCEAE8',
    main: '#DA2818',
    dark: '#DA2818',
    contrastText: '#FFFFFF'
  },
  purple: {
    light: '#F1EBF5',
    main: '#AC32E4',
    dark: '#AC32E4',
    contrastText: '#000000'
  },
  white: {
    light: '#FFFFFF',
    main: '#FFFFFF',
    dark: '#FFFFFF',
    contrastText: '#000000'
  }
};

const colorsDark = {
  ...colorsLight,
  ...{
    grey: {
      light: '#DDE2EA',
      main: '#C5CBD6',
      dark: '#9CA5B4',
      contrastText: '#000000'
    }
  }
};

const gradients = {
  gr1: `linear-gradient(90deg, #F43686 0%, #FF9eC7 100%)`,
  gr2: `linear-gradient(90deg, #FC6076 0%, #FF9A44 100%)`,
  gr3: `linear-gradient(90deg, #AC32E4 0%, #7918F2 48%, #4801FF 100%)`,
  gr4: `linear-gradient(118deg, #EC008C 0%, #fC6767 100%)`,
  gr5: `linear-gradient(90deg, #00c193 0%, #176be8 100%)`,
  gr6: `linear-gradient(180deg, #DA2818 0%, #EE6604 100%)`,
  gr7: `linear-gradient(
    180deg,
    #f98fcf 0%,
    rgba(90, 35, 246, 0.45) 45%,
    rgb(236, 128, 188) 65%,
    rgb(230, 145, 200) 80%,
    rgb(230, 145, 200) 90%,
    rgb(240, 145, 190) 100%
  )`
};

// declare module '@mui/material/styles/createTypography' {
//   interface FontStyle {
//     subtitle3: TypographyStyleOptions;
//   }
// }

const typography: TypographyOptions = {
  fontFamily: 'Inter, sans-serif',
  htmlFontSize: 10,
  fontSize: 16,
  h1: {
    fontSize: '3.6rem',
    fontWeight: 'bold',
    lineHeight: 1.2,
    letterSpacing: 0
  },
  h2: {
    fontSize: '2.6rem',
    fontWeight: 'bold',
    lineHeight: 1.2,
    letterSpacing: 0
  },
  h3: {
    fontSize: '2rem',
    fontWeight: 'bold',
    lineHeight: 1.2,
    letterSpacing: 0
  },
  h4: {
    fontSize: '1.8rem',
    fontWeight: 'normal',
    lineHeight: 1.2,
    letterSpacing: 0
  },
  h5: {
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: 0
  },
  body1: {
    fontSize: '1.6rem',
    fontWeight: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0
  },
  body2: {
    fontSize: '1.6rem',
    fontWeight: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0
  },
  subtitle1: {
    color: '#7A809D',
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: 2.4,
    letterSpacing: 0
  },
  subtitle2: {
    fontWeight: 'normal',
    fontSize: '1.2rem',
    lineHeight: '2.4rem',
    textAlign: 'center',
    color: '#605866'
  },
  overline: {
    fontSize: '1.1rem',
    color: 'rgba(0,0,0,0.25)',
    opacity: 0.66
  },
  caption: {
    fontSize: '1.2rem',
    lineHeight: '1.6rem'
  },
  button: {
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: 0,
    textTransform: 'none',
    fontSize: '2rem'
  }
};

const base: ThemeOptions = Object.assign({
  typography: typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 956,
      lg: 1200,
      xl: 1920
    }
  }
});

const darkTheme = createTheme({
  ...base,
  colors: { ...colorsDark },
  gradients: { ...gradients },
  palette: {
    background: {
      paper: colorsDark.grey.main,
      default: colorsDark.grey.light
    }
  }
});

let lightTheme = createTheme({
  ...base,
  colors: { ...colorsLight },
  gradients: { ...gradients }
});

lightTheme = createTheme(lightTheme, {
  palette: {
    type: 'light',
    background: {
      paper: lightTheme.colors.white.main,
      default: lightTheme.colors.white.main
    },
    primary: { ...lightTheme.colors.pink },
    secondary: { ...lightTheme.colors.grey },

    info: { ...lightTheme.colors.blue },
    error: { ...lightTheme.colors.red },
    success: { ...lightTheme.colors.green },
    warning: { ...lightTheme.colors.orange },

    divider: 'rgba(0,0,0,0.10)',
    text: {
      primary: lightTheme.colors.charcoal.main,
      secondary: lightTheme.colors.grey.dark,
      disabled: lightTheme.colors.grey.dark,
      hint: lightTheme.colors.grey.dark
    },
    action: {
      disabledBackground: 'rgba(0,0,0,0.1)',
      disabled: lightTheme.colors.grey.dark
    }
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        flexItem: {
          // height: '1px'
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: '7px 0',
          width: '44px'
        },
        thumb: {
          boxShadow: 'none'
        },
        switchBase: {
          top: '7px',
          padding: '2px',
          color: lightTheme.colors.white.main,
          '&.Mui-checked': {
            color: lightTheme.colors.white.main
          },
          '&.Mui-checked + .MuiSwitch-track': {
            opacity: 1
          },
          '&.Mui-disabled': {
            color: '#bdbdbd'
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: lightTheme.colors.black.main
          }
        },
        colorPrimary: {
          '&$checked': {
            color: lightTheme.colors.white.main
          }
        },
        colorSecondary: {
          '&$checked': {
            color: lightTheme.colors.white.main
          }
        },
        track: {
          borderRadius: '100px',
          opacity: 1,
          backgroundColor: lightTheme.colors.grey.light
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [lightTheme.breakpoints.up('sm')]: {
            paddingLeft: '16px',
            paddingRight: '16px'
          }
        },
        maxWidthMd: {
          [lightTheme.breakpoints.up('md')]: {
            // width: '680px',
            // maxWidth: '680px'
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthXs: {
          maxWidth: '400px'
        },
        paperWidthSm: {
          maxWidth: '668px'
        },
        paperWidthMd: {
          maxWidth: '756px'
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.25);'
        },
        invisible: {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          // padding: '0.7rem 2.4rem',
          minHeight: '4.8rem',
          fontWeight: 500,
          color: lightTheme.colors.charcoal.main
        },
        sizeSmall: {
          height: 'auto',
          fontSize: '1.6rem',
          padding: '0.2rem 0.9rem'
        },
        sizeLarge: {
          height: 'auto',
          fontSize: '1.6rem'
        },
        text: {
          padding: '0.4rem 0.8rem',
          height: 'auto',
          minHeight: 'auto',
          fontSize: 'inherit',
          fontWeight: 'normal'
        },
        textPrimary: {
          color: lightTheme.colors.pink.main
        },
        textSecondary: {
          color: lightTheme.colors.charcoal.main
        },

        textSizeLarge: {
          // padding: '0.7rem 2.4rem',
          minHeight: '4.8rem'
        },
        outlinedPrimary: {
          color: lightTheme.colors.pink.main,
          border: `2px solid ${lightTheme.colors.pink.main}`,
          '&:hover': {
            border: `2px solid ${lightTheme.colors.pink.main}`
          }
        },
        outlinedSecondary: {
          fontSize: '1.6rem',
          color: lightTheme.colors.charcoal.main,
          border: `1px solid ${lightTheme.colors.grey.main}`,
          '&:hover': {
            border: `1px solid ${lightTheme.colors.grey.main}`
          }
        },
        contained: {
          '&.Mui-disabled': {
            color: lightTheme.colors.grey.light,
            backgroundColor: lightTheme.colors.purple.light
          }
        },
        containedPrimary: {
          color: lightTheme.colors.white.main,
          '&:hover': {
            backgroundColor: '#FE6BA9'
          }
        },
        containedSecondary: {
          color: lightTheme.colors.charcoal.main,
          fontWeight: 500,
          backgroundColor: '#F1F6FB',
          '&:hover': {
            backgroundColor: '#c3d8ed'
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: lightTheme.colors.charcoal.main,
          padding: '0.8rem'
        },
        colorPrimary: {
          color: lightTheme.colors.pink.main
        },
        colorSecondary: {
          color: lightTheme.colors.charcoal.main
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px'
        },
        notchedOutline: {
          borderColor: lightTheme.colors.purple.light
        },
        input: {
          // padding: '15.5px 14px',
          padding: '12.5px 14px'
        },
        multiline: {
          padding: 0
        },
        inputMultiline: {}
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        outlined: ({ ownerState }: any) => ({
          transform: 'translate(14px, 12px) scale(1)',

          ...(ownerState.shrink && {
            transform: 'translate(14px, -8px) scale(0.75)'
          })
        }),
        // outlined: {
        //   //       lineHeight: 1.4,
        //   //       maxWidth: 'calc(100% - 46px)',
        //   transform: 'translate(14px, 12px) scale(1)',
        //   //       '&$marginDense': {
        //   //         transform: 'translate(14px, 14px) scale(1)'
        //   //       },

        //   '&$shrink': {
        //     border: '1px solid lime',
        //     transform: 'translate(14px, -8px) scale(0.75)'
        //     //         lineHeight: 1.4,
        //     //         maxWidth: '115%'
        //   }
        // },
        asterisk: {
          color: lightTheme.colors.pink.main
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          [lightTheme.breakpoints.up('md')]: {
            fontSize: '1.6rem'
          },
          fontSize: '1.6rem'
        },
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 100px ${lightTheme.colors.white.main} inset`,
            filter: 'none'
          }
        },
        multiline: {
          padding: '18.5px 14px'
        },
        inputMultiline: {
          padding: 0
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          color: lightTheme.colors.white.main,
          fontWeight: 600
        },
        deleteIconColorPrimary: {
          color: lightTheme.colors.white.main,
          '&:hover': {
            color: lightTheme.colors.white.main
          },
          '&:active': {
            color: lightTheme.colors.white.main
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: lightTheme.colors.purple.light,

          '& .MuiSvgIcon-root:first-child': {
            color: lightTheme.colors.purple.light
          },
          '& .MuiSvgIcon-root': {
            fontSize: '2.4rem'
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          paddingTop: '5px',
          paddingLeft: '8px',
          paddingBottom: '5px'
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          maxHeight: '66%'
        }
      }
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',

          '&.MuiSnackbar-anchorOriginTopCenter': {
            top: 0
          },
          '&.MuiSnackbar-anchorOriginBottomCenter': {
            bottom: 0,

            '@media (max-width: 768px)': {
              bottom: '80px'
            }
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '1.6rem',
          lineHeight: '2.4rem',
          userSelect: 'none'
        },
        standardError: {
          color: '#FF2E56',
          backgroundColor: lightTheme.colors.white.main,
          border: 'none',
          icon: {
            color: '#FF2E56'
          }
        },
        standardInfo: {
          color: lightTheme.colors.blue.main,
          backgroundColor: lightTheme.colors.blue.main,
          border: 'none',
          icon: {
            color: lightTheme.colors.blue.main
          }
        },
        standardSuccess: {
          color: lightTheme.colors.green.main,
          backgroundColor: lightTheme.colors.white.main,
          border: 'none',
          icon: {
            color: lightTheme.colors.green.main
          }
        },
        filledError: {
          color: lightTheme.colors.white.main,
          fontWeight: 400,
          backgroundColor: '#FF2E56'
        },
        filledInfo: {
          color: lightTheme.colors.white.main,
          fontWeight: 400
        },
        filledSuccess: {
          color: lightTheme.colors.white.main,
          fontWeight: 400
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '8px',
          borderRadius: '2px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          textAlign: 'justify'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          '&.Mui-selected': {
            opacity: 1
          }
        },
        textColorPrimary: {
          color: lightTheme.colors.pink.main,
          opacity: 0.5
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          WebkitOverflowScrolling: 'touch',
          overscrollBehavior: 'contain',
          scrollBehavior: 'smooth',
          '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
            display: 'none'
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: lightTheme.colors.white.main
        }
      }
    },
    MuiTouchRipple: {
      styleOverrides: {
        rippleVisible: {
          opacity: 0.2
        },
        '@keyframes enter': {
          '0%': {
            transform: 'scale(0)',
            opacity: 0.1
          },
          '100%': {
            transform: 'scale(1)',
            opacity: 0.2
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: '1.6rem'
        },
        gutterBottom: {
          marginBottom: '1.6rem'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: lightTheme.colors.blue.main,
          textDecorationColor: lightTheme.colors.blue.main
        },
        underlineHover: {
          textDecoration: 'underline',
          '&:hover': {
            textDecoration: 'none'
          }
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            borderColor: lightTheme.colors.pink.main,
            borderLeft: '1px solid #f43686!important'
          }
        },
        sizeSmall: {
          fontSize: '1.6rem',
          fontWeight: 500
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          borderRadius: '0.8rem'
        }
      }
    }
  }
});

export { darkTheme };
export { lightTheme };

export const minWidthForPage = 360;
export const headerHeight = 72;
export const footerHeight = 64;
export const asideMenuTableWidth = 88;
export const asideMenuDesktopWidth = 276;
export const authFormWidthNarrow = 380;
export const authFormWidth = 520;
