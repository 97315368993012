import { css } from 'styled-components';
import { Breakpoint, Theme } from '@mui/material/styles';

export function XS(style: ReturnType<typeof css>) {
  return css`
    ${props => props.theme.breakpoints.up('xs')} {
      ${style}
    }
  `;
}

export function SM(style: ReturnType<typeof css>) {
  return css`
    ${props => props.theme.breakpoints.up('sm')} {
      ${style}
    }
  `;
}
export function MD(style: ReturnType<typeof css>) {
  return css`
    ${props => props.theme.breakpoints.up('md')} {
      ${style}
    }
  `;
}
export function LG(style: ReturnType<typeof css>) {
  return css`
    ${props => props.theme.breakpoints.up('lg')} {
      ${style}
    }
  `;
}

export function XL(style: ReturnType<typeof css>) {
  return css`
    ${props => props.theme.breakpoints.up('xl')} {
      ${style}
    }
  `;
}
export function XSDown(style: ReturnType<typeof css>) {
  return css`
    ${props => props.theme.breakpoints.down('xs')} {
      ${style}
    }
  `;
}

export function SMDown(style: ReturnType<typeof css>) {
  return css`
    ${props => props.theme.breakpoints.down('sm')} {
      ${style}
    }
  `;
}
export function MDDown(style: ReturnType<typeof css>) {
  return css`
    ${props => props.theme.breakpoints.down('md')} {
      ${style}
    }
  `;
}
export function LGDown(style: ReturnType<typeof css>) {
  return css`
    ${props => props.theme.breakpoints.down('lg')} {
      ${style}
    }
  `;
}

export function XLDown(style: ReturnType<typeof css>) {
  return css`
    ${props => props.theme.breakpoints.down('xl')} {
      ${style}
    }
  `;
}

export function SMtoMD(style: ReturnType<typeof css>) {
  return css`
    ${props => props.theme.breakpoints.between('sm', 'md')} {
      ${style}
    }
  `;
}

export function SMtoLG(style: ReturnType<typeof css>) {
  return css`
    ${props => props.theme.breakpoints.between('sm', 'lg')} {
      ${style}
    }
  `;
}

export function SMtoXL(style: ReturnType<typeof css>) {
  return css`
    ${props => props.theme.breakpoints.between('sm', 'xl')} {
      ${style}
    }
  `;
}

export function getScreenClass(theme: Theme): Breakpoint {
  const viewport = window.innerWidth;
  let result: Breakpoint = 'xs';

  for (let breakpoint of theme.breakpoints.keys) {
    if (viewport >= theme.breakpoints.values[breakpoint]) result = breakpoint;
  }

  return result;
}

export interface optimizeOptions {
  initialUrl: string;
  width?: number;
  height?: number;
  fit?: 'contain' | 'cover';
}

export function optimizePicture(optimizeOptions: optimizeOptions): string {
  const { initialUrl, width, height, fit } = optimizeOptions;
  return (
    initialUrl &&
    `https://images.fanscity.com/cdn-cgi/image/fit=${fit},width=${width},height=${height}${initialUrl.replace(
      new RegExp('http[s]?:\\/\\/[^\\/]*(.*)'),
      '$1'
    )}`
  );
}
