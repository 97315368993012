import styled, { css } from 'styled-components';

export const FullPageWrap = styled('div')<{ $isOverflowHidden?: boolean }>`
  width: 100%;
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;

  ${props =>
    props.$isOverflowHidden &&
    css`
      height: 100vh;
      overflow: hidden;
    `}
`;
