export enum ValidationResult {
  Ok,
  Required,
  TooShort,
  TooLong,
  InvalidFormat,
  Taken,
  Disposable,
  Similar,
  TooSimple,
  TooSimpleShort,
  Personal,
  Sequence,
  TooYoung,
  NoLowercase,
  NoUppercase,
  NoNumber,
  NoSpecial
}

export type ValidationStatus_Key = 'OK' | 'INVALID' | 'TAKEN' | 'DISPOSABLE';
export type ValidationStatus_Value = 0 | 1 | 2 | 3;

export const ValidationStatus = {
  OK: {
    value: 0,
    options: {}
  },
  INVALID: {
    value: 1,
    options: {}
  },
  TAKEN: {
    value: 2,
    options: {}
  },
  DISPOSABLE: {
    value: 3,
    options: {}
  }
} as { [K in ValidationStatus_Key]: { value: ValidationStatus_Value; options: any } };
