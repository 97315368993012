import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export default function LanguageChecker({ children }: { children: React.ReactNode }) {
  const { lang } = useParams();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!lang || !Object.keys(i18n.services.resourceStore.data).includes(lang)) {
      navigate('../');
    }

    if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }

    // return () => {
    //   i18n.changeLanguage('en');
    // };
  });

  return <>{children}</>;
}
