import CssBaseline from '@mui/material/CssBaseline';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '../style/global_style';
import { lightTheme } from '../style/themes';
import MainContent from './MainContent';
import ScrollToTop from './ScrollToTop';

export default function Root() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <GlobalStyle />

        <BrowserRouter>
          <ScrollToTop />
          <MainContent />
        </BrowserRouter>
      </ThemeProvider>
    </HelmetProvider>
  );
}
