import React from 'react';
import Box from '@mui/material/Box';
import MessageSuccess from './components/MessageSuccess';
import MessageError from './components/MessageError';
import CustomTextField from '../../../ui/components/custom/CustomTextField';
import { createLandingReport } from '../../../api/APIClient';
import {
  StyledButton,
  StyledForm,
  StyledTitleForm,
  StyledWrapForm
} from './components/Hero.styles';
import {Stack } from '@mui/material';
import { observer } from 'mobx-react';
import { action, makeObservable, observable } from 'mobx';
import { createRequiredValidator } from '../../../utils/validators/required';
import { createEmailValidator } from '../../../utils/validators/email';
import { withTranslation, WithTranslation } from 'react-i18next';

@observer
class ShoutoutEmbedPage extends React.Component<WithTranslation> {
  constructor(props: any) {
    super(props);

    makeObservable(this);
  }

  name = createRequiredValidator();
  email = createEmailValidator();
  messenger = createRequiredValidator();

  @observable links = '';
  @observable showSuccess = false;
  @observable showError = false;

  @action.bound
  async handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();

    if (this.checkValidation()) {
      const data = {
        name: this.name.value,
        email: this.email.value,
        message: this.messenger.value,
        socialLinks: this.links
      };

      const resp = await createLandingReport(data);

      if (resp.err) {
        this.showError = true;
        this.showSuccess = false;
      } else {
        this.showSuccess = true;
        this.showError = false;
      }
    }
  }

  @action.bound
  showSuccessMessage() {}

  @action.bound
  checkValidation() {
    [this.name, this.email, this.messenger].forEach(field => {
      field.resetDirty(true);
      field.revalidate();
    });

    return !this.name.isValid || !this.email.isValid || !this.messenger.isValid ? false : true;
  }

  @action.bound
  handleChangeName(ev: React.ChangeEvent<HTMLInputElement>) {
    this.name.set(ev.target.value);
  }

  @action.bound
  handleChangeEmail(ev: React.ChangeEvent<HTMLInputElement>) {
    this.email.set(ev.target.value);
  }

  @action.bound
  handleChangeMsg(ev: React.ChangeEvent<HTMLInputElement>) {
    this.messenger.set(ev.target.value);
  }

  @action.bound
  handleChangeLinks(ev: React.ChangeEvent<HTMLInputElement>) {
    this.links = ev.target.value;
  }

  render() {
    const { t } = this.props;

    return (
      <StyledForm noValidate autoComplete="on" name="shoutout" onSubmit={this.handleSubmit}>
        <StyledWrapForm>
          <StyledTitleForm>{t('shoutout.hero.form.title')}</StyledTitleForm>
          <Stack direction="column" spacing={3.5}>
            <Box>
              <Box mb={-2}>
                <CustomTextField
                  label={t('shoutout.hero.form.inputs.name')}
                  required
                  value={this.name.value}
                  onChange={this.handleChangeName}
                  error={(this.name.isDirty && !this.name.value.length) || this.name.isInvalid}
                  helperText={this.name.errorMessage || ' '}
                  margin="none"
                  autoComplete="name"
                />
              </Box>
            </Box>

            <Box>
              <Box mb={-2}>
                <CustomTextField
                  label={t('shoutout.hero.form.inputs.email')}
                  required
                  value={this.email.value}
                  onChange={this.handleChangeEmail}
                  error={(this.email.isDirty && !this.email.value.length) || this.email.isInvalid}
                  helperText={this.email.errorMessage || ' '}
                  margin="none"
                  autoComplete="email"
                />
              </Box>
            </Box>

            <CustomTextField
              label={t('shoutout.hero.form.inputs.telegram_or_whatsup')}
              value={this.messenger.value}
              required
              error={
                (this.messenger.isDirty && !this.messenger.value.length) || this.name.isInvalid
              }
              helperText={this.messenger.errorMessage || ' '}
              onChange={this.handleChangeMsg}
              margin="none"
              autoComplete="message"
            />

            <CustomTextField
              multiline
              minRows="3"
              maxRows="3"
              label={t('shoutout.hero.form.inputs.social_links')}
              value={this.links}
              onChange={this.handleChangeLinks}
              margin="none"
              autoComplete="link"
            />

            <Box>
              <StyledButton type="submit" variant="contained" disableElevation fullWidth>
                {t('shoutout.hero.form.button')}
              </StyledButton>
            </Box>
          </Stack>
        </StyledWrapForm>

        <div className="messages">
          <Box display={this.showSuccess ? 'block' : 'none'}>
            <MessageSuccess />
          </Box>

          <Box display={this.showError ? 'block' : 'none'}>
            <MessageError
              onClose={() => {
                this.showError = false;
              }}
            />
          </Box>
        </div>
      </StyledForm>
    );
  }
}

export default withTranslation()(ShoutoutEmbedPage);
