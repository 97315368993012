import styled from 'styled-components';
import fanscity_logo_big from '../assets/logo/fanscity_logo_big.png';

const StyledLogoDiv = styled('div')`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75% auto;
  background-image: url(${fanscity_logo_big});
  user-select: none;
`;

export default function HomePage() {
  return <StyledLogoDiv />;
}
