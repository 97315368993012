import Box from '@mui/material/Box';
import { Icon as IconifyIcon } from '@iconify/react';

export default function LoadingSpinner(props: { large?: boolean; small?: boolean }) {
  return (
    <Box textAlign="center" width="100%" alignSelf="center">
      <IconifyIcon
        className="spin"
        icon="mdi:loading"
        width={props.large ? '6.4rem' : props.small ? '2rem' : '3.2rem'}
        height={props.large ? '6.4rem' : props.small ? '2rem' : '3.2rem'}
        color="#F43686"
      />
    </Box>
  );
}

export function LoadingSpinnerIcon(props: { width?: number; height?: number; color?: string }) {
  return (
    <IconifyIcon
      className="spin"
      icon="mdi:loading"
      width={props.width ? props.width : 22}
      height={props.height ? props.height : 22}
      color={props.color ? props.color : '#F43686'}
    />
  );
}
