import { NewLandingReport, ILandingReport, PublicUser, LinksResp } from 'fanpoint-types';
import i18n from '../i18n/config';
import { post } from './Api';

export interface IEmpty {}

export const createLandingReport = async (
  requestData: NewLandingReport
): Promise<IResponse<ILandingReport>> => {
  const extendedData: NewLandingReport = { ...requestData, referrerUrl: window.location.href };
  console.log('⬆︎', 'createLandingReport', extendedData);
  let resp: IResponse<any> = {};

  try {
    window.gtag('event', 'market_pre_reg', {
      language: i18n.language
    });

    const payload = await post('landingReports/create', extendedData);

    resp.payload = payload;
    console.log('⬇︎', 'createLandingReport', resp);
  } catch (err) {
    resp.err = err;
    console.error('⬇︎', 'createLandingReport', 'ERROR', err);
  } finally {
    return resp;
  }
};

export const getSinglePublicUser = async (id: string): Promise<IResponse<PublicUser>> => {
  console.log('⬆︎', 'getSinglePublicUser');
  let resp: IResponse<PublicUser> = {};

  try {
    const payload = await post(`users/getSinglePublic/${id}`, {});

    resp.payload = payload;
    console.log('⬇︎', 'getSinglePublicUser', resp);
  } catch (err) {
    resp.err = err;
    console.error('⬇︎', 'getSinglePublicUser', 'ERROR', err);
  } finally {
    return resp;
  }
};

export const listLinks = async (userSub: string): Promise<IResponse<LinksResp>> => {
  console.log('⬆︎', 'listLinks');
  let resp: IResponse<LinksResp> = {};

  try {
    const payload = (await post(`links/listLinks`, {
      userId: userSub
    })) as LinksResp;

    resp.payload = payload;
    console.log('⬇︎', 'listLinks', resp);
  } catch (err) {
    resp.err = err;
    console.log('⬇︎', 'ERROR', err);
  } finally {
    return resp;
  }
};
