import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Icon as IconifyIcon } from '@iconify/react';

const ButtonPlaceholder = ({
  handleClick,
  collapsed
}: {
  handleClick: () => void;
  collapsed: boolean;
}) => {
  return (
    <Box py={3}>
      <Button
        onClick={handleClick}
        fullWidth
        disableElevation
        variant="contained"
        endIcon={
          collapsed ? (
            <IconifyIcon icon="mdi:chevron-down" width={24} height={24} />
          ) : (
            <IconifyIcon icon="mdi:chevron-up" width={24} height={24} />
          )
        }>
        18+ Links
      </Button>
    </Box>
  );
};

export default ButtonPlaceholder;
