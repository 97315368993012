import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import bg_desktop from '../assets/bg_desktop.jpg';
import bg_mobile from '../assets/bg_mobile.jpg';

const StyledWrap = styled('div')<{ $isMobile?: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7e797e;
  background-image: url(${props => (props.$isMobile ? bg_mobile : bg_desktop)});
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  z-index: 999999;
`;

const StyledMessage = styled('div')<{ $isMobile?: boolean }>`
  width: ${props => (props.$isMobile ? 'auto' : '552px')};
  padding: ${props => (props.$isMobile ? '40px' : '80px')} 20px;
  margin: 0 16px;
  background-color: #f43686;
  border-radius: 16px;
  overflow: hidden;
  white-space: pre-wrap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: ${props => (props.$isMobile ? '20px' : '32px')};
  line-height: ${props => (props.$isMobile ? '24px' : '38px')};
  text-align: center;
  color: #ffffff;
`;

export default function MessageSuccess() {
  const { t } = useTranslation();
  const msg = t('shoutout.msg_success');

  return (
    <StyledWrap $isMobile={isMobile}>
      <StyledMessage $isMobile={isMobile}>{msg}</StyledMessage>
    </StyledWrap>
  );
}
