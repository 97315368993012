import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import bgDesktop from '../assets/hero_bg_desktop.jpg';
import bgMobile from '../assets/hero_bg_mobile.jpg';
import Button from '@mui/material/Button';
import dotGroupLeft from '../assets/dot_group_left_desktop.png';
import dotGroupRight from '../assets/dot_group_right_desktop.png';

export const StyledWrap = styled('div')<{ $specialOccasion1?: boolean }>`
  ${$specialOccasion1 =>
    $specialOccasion1 &&
    css`
      min-height: ${() => (isMobile ? '100vh' : 'calc(100vh - 96px)')};
    `}

  background: url(${() => (isMobile ? bgMobile : bgDesktop)}) no-repeat top center;
  background-size: cover;
  padding: ${() => (isMobile ? '128px 0 32px' : '32px 0')};
  margin-top: 0;
  position: relative;
`;

export const StyledDots = styled('div')<{ $side: 'left' | 'right' }>`
  background-size: cover;
  position: absolute;
  width: 143px;
  height: 259px;
  z-index: 1;

  ${({ $side }) =>
    $side === 'left' &&
    css`
      background: url(${dotGroupLeft}) no-repeat top center;
      left: 0;
      top: 490px;
    `};

  ${({ $side }) =>
    $side === 'right' &&
    css`
      background: url(${dotGroupRight}) no-repeat top center;
      right: 0;
      top: 110px;
    `};
`;

export const StyledTitle = styled('h2')`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: ${() => (isMobile ? '46px' : '96px')};
  line-height: ${() => (isMobile ? '46px' : '104px')};
  text-align: ${() => (isMobile ? 'left' : 'center')};
  letter-spacing: -2px;
  color: #ffffff;
  margin: 0 0 ${() => (isMobile ? '24px' : '28px')};
`;

export const StyledWrapForm = styled('div')`
  overflow: hidden;
  background-color: #ffffff;
  border: 1px solid #cfd9de;
  border-radius: 10px;
  max-width: ${() => (isMobile ? '100%' : '448px')};
  margin: 0 auto;
  padding: ${() => (isMobile ? '24px 12px ' : '24px 32px')};
`;

export const StyledTitleForm = styled('p')`
  margin: 0 0 ${() => (isMobile ? '28px' : '32px')};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  color: #2f2a32;
  font-size: ${() => (isMobile ? '16px' : '18px')};
  line-height: ${() => (isMobile ? '20px' : '24px')};
  text-align: ${() => (isMobile ? 'left' : 'center')};
`;

export const StyledButton = styled(Button)``;

export const StyledForm = styled('form')`
  width: 100%;
  margin: 20px auto;
  overflow: hidden;

  >div {
    border: none;
    padding: 20px;
  }

  .messages {
    padding: 0;
  }
`;