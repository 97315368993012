import { BrowserView, isMobile } from 'react-device-detect';
import { SectionWrapWithPaddingY } from '../styles/styledComponents';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CustomTextField from '../../../../ui/components/custom/CustomTextField';
import { createEmailRequiredValidator } from '../../../../utils/validators/email';
import { observer } from 'mobx-react';
import React from 'react';
import { action, makeObservable, observable } from 'mobx';
import { createRequiredValidator } from '../../../../utils/validators/required';
import { NewLandingReport } from 'fanpoint-types';
import { Markup } from 'interweave';
import { WithTranslation, withTranslation } from 'react-i18next';
import { StyledButton, StyledDots, StyledTitle, StyledTitleForm, StyledWrap, StyledWrapForm } from './Hero.styles';

@observer
class Hero extends React.Component<
  WithTranslation & {
    onSubmit: (data: NewLandingReport) => void;
    specialOccasion1?: boolean;
  }
> {
  constructor(props: any) {
    super(props);

    makeObservable(this);
  }


  name = createRequiredValidator();
  email = createEmailRequiredValidator();
  messenger = createRequiredValidator();

  @observable links = '';

  @action.bound
  handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();

    if (this.checkValidation()) {
      const data = {
        name: this.name.value,
        email: this.email.value,
        message: this.messenger.value,
        socialLinks: this.links,
      };

      this.props.onSubmit(data);
    }
  }

  @action.bound
  checkValidation() {
    [this.name, this.email, this.messenger].forEach((field) => {
      field.resetDirty(true);
      field.revalidate();
    })

    return (!this.name.isValid || !this.email.isValid || !this.messenger.isValid)
      ? false
      : true;
  }

  @action.bound
  handleChangeName(ev: React.ChangeEvent<HTMLInputElement>) {
    this.name.set(ev.target.value);
  }

  @action.bound
  handleChangeEmail(ev: React.ChangeEvent<HTMLInputElement>) {
    this.email.set(ev.target.value);
  }

  @action.bound
  handleChangeMsg(ev: React.ChangeEvent<HTMLInputElement>) {
    this.messenger.set(ev.target.value);
  }

  @action.bound
  handleChangeLinks(ev: React.ChangeEvent<HTMLInputElement>) {
    this.links = ev.target.value;
  }


  render() {
    const { t } = this.props;

    const title = isMobile ? (
      <Markup content={t('shoutout.hero.title.mob')} />
    ) : (
      <Markup content={t('shoutout.hero.title.desk')} />
    );

    return (
      <StyledWrap $specialOccasion1={this.props.specialOccasion1}>
        <BrowserView>
          <StyledDots $side="left" />
          <StyledDots $side="right" />
        </BrowserView>

        <SectionWrapWithPaddingY $aBitOfZindexPls>
          <StyledTitle>{title}</StyledTitle>

          <form noValidate autoComplete="on" name="shoutout" onSubmit={this.handleSubmit}>
            <StyledWrapForm>
              <StyledTitleForm>{t('shoutout.hero.form.title')}</StyledTitleForm>
              <Stack direction="column" spacing={3.5}>
                <Box>
                  <Box mb={-2}>
                    <CustomTextField
                      label={t('shoutout.hero.form.inputs.name')}
                      required
                      value={this.name.value}
                      onChange={this.handleChangeName}
                      error={(this.name.isDirty && !this.name.value.length) || this.name.isInvalid}
                      helperText={this.name.errorMessage || ' '}
                      margin="none"
                      autoComplete="name"
                    />
                  </Box>
                </Box>

                <Box>
                  <Box mb={-2}>
                    <CustomTextField
                      label={t('shoutout.hero.form.inputs.email')}
                      required
                      value={this.email.value}
                      onChange={this.handleChangeEmail}
                      error={
                        (this.email.isDirty && !this.email.value.length) || this.email.isInvalid
                      }
                      helperText={this.email.errorMessage || ' '}
                      margin="none"
                      autoComplete="email"
                    />
                  </Box>
                </Box>

                <CustomTextField
                  label={t('shoutout.hero.form.inputs.telegram_or_whatsup')}
                  value={this.messenger.value}
                  required
                  error={(this.messenger.isDirty && !this.messenger.value.length) || this.name.isInvalid}
                  helperText={this.messenger.errorMessage || ' '}
                  onChange={this.handleChangeMsg}
                  margin="none"
                  autoComplete="message"
                />

                <CustomTextField
                  multiline
                  minRows="3"
                  maxRows="3"
                  label={t('shoutout.hero.form.inputs.social_links')}
                  value={this.links}
                  onChange={this.handleChangeLinks}
                  margin="none"
                  autoComplete="link"
                />

                <Box>
                  <StyledButton type="submit" variant="contained" disableElevation fullWidth>
                    {t('shoutout.hero.form.button')}
                  </StyledButton>
                </Box>
              </Stack>
            </StyledWrapForm>
          </form>
        </SectionWrapWithPaddingY>
      </StyledWrap>
    );
  }
}

export default withTranslation()(Hero);
