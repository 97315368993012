import Stack from '@mui/material/Stack';
import { Link } from 'fanpoint-types';
import MyLink from './MyLink';

const LinksPlaceholder = ({ links }: { links: Link[] }) => {
  return (
    <Stack direction="column" spacing={2}>
      {links.map(link =>
        link.link ? <MyLink drawAsLink={true} myLinkData={link} key={link.id} /> : null
      )}
    </Stack>
  );
};

export default LinksPlaceholder;
