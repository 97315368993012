import i18n from '../../i18n/config';
import { ValidationResult } from './result';
import { ValidatedStringValue } from '../values/ValidatedStringValue';

export const MaxNameLength = 32;

const RequiredValidationMessages = {
  [ValidationResult.Ok]: () => '',
  [ValidationResult.Required]: () => i18n.t('validation.required')
};

export async function validateRequired(value?: string) {
  if (!value?.trim().length) return ValidationResult.Required;
  return ValidationResult.Ok;
}

export class RequiredValue extends ValidatedStringValue<typeof RequiredValidationMessages> {}

export function createRequiredValidator() {
  return new RequiredValue(validateRequired, RequiredValidationMessages);
}
