import { Link } from 'fanpoint-types';
import MyLintUITemplate from './MyLintUITemplate';

interface IMyLint {
  drawAsLink?: boolean;
  myLinkData: Partial<Link>;
}

export default function MyLint({ drawAsLink, myLinkData }: IMyLint) {
  const { title, displayResourceName, link, avatarLink, resourceLogo, type } = myLinkData;

  return (
    <MyLintUITemplate
      resourceImg={resourceLogo}
      title={title}
      siteName={displayResourceName}
      siteUrl={link}
      img={avatarLink}
      drawAsLink={drawAsLink}
      type={type}
    />
  );
}
