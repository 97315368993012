import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import en from './translations/en.json';
import ru from './translations/ru.json';

export type Language_KEY = 'en' | 'ru';

export const LANGUAGES: Language_KEY[] = ['en', 'ru'];

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    supportedLngs: LANGUAGES,
    resources: {
      en: { translation: en },
      ru: { translation: ru }
    }
  });

export default i18n;
