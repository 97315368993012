import { API } from 'aws-amplify';

export const get = async (path: string) => {
  return API.get('api', path, {});
};

export const remove = async (path: string) => {
  return API.del('api', path, {});
};

export const post = async (path: string, body: {}) => {
  return API.post('api', path, {
    body: body
  });
};

export const update = async (path: string, body: {}) => {
  return API.put('api', path, {
    body: body
  });
};
