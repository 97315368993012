import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FullPageWrap } from '../../../style/styledComponents';
import Cards from './components/Cards';
import Description from './components/Description';
import Footer from './components/Footer';
import Header from './components/Header';
import Hero from './components/Hero';
import Carousel from './components/Carousel';
import MessageSuccess from './components/MessageSuccess';
import bg_desktop from './assets/bg_desktop.jpg';
import bg_mobile from './assets/bg_mobile.jpg';
import useImagePreloader from '../../../hooks/useImagePreloader';
import Box from '@mui/material/Box';
import { isMobile } from 'react-device-detect';
import { NewLandingReport } from 'fanpoint-types';
import { createLandingReport } from '../../../api/APIClient';
import MessageError from './components/MessageError';
import { useTranslation } from 'react-i18next';
import { Markup } from 'interweave';

const preloadSrcList: string[] = [];

if (isMobile) {
  preloadSrcList.push(bg_mobile);
} else {
  preloadSrcList.push(bg_desktop);
}

export default function ShoutoutPage() {
  const { t } = useTranslation();

  const [showMessageSuccess, setShowMessageSuccess] = useState(false);
  const [showMessageError, setShowMessageError] = useState(false);
  const cardsContent = [
    {
      title: isMobile ? (
        <Markup content={t('shoutout.cards.first.one.title.mob')} />
      ) : (
        t('shoutout.cards.first.one.title.desk')
      ),
      description: t('shoutout.cards.first.one.description')
    },
    {
      title: t('shoutout.cards.first.two.title'),
      description: t('shoutout.cards.first.two.description')
    },
    {
      title: t('shoutout.cards.first.three.title'),
      description: t('shoutout.cards.first.three.description'),
    }
  ];

  const handleSubmit = async (data: NewLandingReport) => {
    const resp = await createLandingReport(data);

    if (resp.err) {
      setShowMessageError(true);
    } else {
      setShowMessageSuccess(true);
    }
  };

  useImagePreloader(preloadSrcList);

  return (
    <FullPageWrap className="shoutout-page" $isOverflowHidden={showMessageSuccess}>
      <Helmet title="FansCity - Shoutout" />

      <Header />

      <Hero onSubmit={handleSubmit} />

      <Description />

      <Carousel />

      <Cards data={cardsContent}/>

      <Footer />

      <Box display={showMessageSuccess ? 'block' : 'none'}>
        <MessageSuccess />
      </Box>

      <Box display={showMessageError ? 'block' : 'none'}>
        <MessageError onClose={() => setShowMessageError(false)} />
      </Box>
    </FullPageWrap>
  );
}
