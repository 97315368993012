import { NavigateFunction, Params, useLocation, useNavigate, useParams } from 'react-router-dom';

export interface WithRouter {
  router: { location: Location; navigate: NavigateFunction; params: Readonly<Params<string>> };
}
export interface WithRouterLocation {
  router: { location: Location };
}

export interface WithRouterNavigate {
  router: { navigate: NavigateFunction };
}

export interface WithRouterParams {
  router: { params: Readonly<Params<string>> };
}

export function withRouter(Component: any) {
  const ComponentWithRouterProp = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  };

  return ComponentWithRouterProp;
}

export function withRouterLocation(Component: any) {
  const ComponentWithRouterProp = (props: any) => {
    const location = useLocation();

    return <Component {...props} router={{ location }} />;
  };

  return ComponentWithRouterProp;
}

export function withRouterNavigate(Component: any) {
  const ComponentWithRouterProp = (props: any) => {
    const navigate = useNavigate();

    return <Component {...props} router={{ navigate }} />;
  };

  return ComponentWithRouterProp;
}

export function withRouterParams(Component: any) {
  const ComponentWithRouterProp = (props: any) => {
    const params = useParams();

    return <Component {...props} router={{ params }} />;
  };

  return ComponentWithRouterProp;
}
