import Button from '@mui/material/Button';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { SectionWrapWithPaddingY } from '../styles/styledComponents';
import { Markup } from 'interweave';
import { useTranslation } from 'react-i18next';

const StyledWrap = styled('div')`
  margin-top: ${() => (isMobile ? '72px' : '100px')};
  margin-bottom: ${() => (isMobile ? '72px' : '100px')};
  text-align: center;
`;

const StyledTitle = styled('h2')`
  margin-top: ${() => (isMobile ? '72px' : '100px')};
  margin-bottom: ${() => (isMobile ? '16px' : '32px')};
  margin-left: 0;
  margin-right: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: ${() => (isMobile ? '46px' : '96px')};
  line-height: ${() => (isMobile ? '46px' : '104px')};
  text-align: ${() => (isMobile ? 'left' : 'center')};
  letter-spacing: -2px;
  color: #2d3436;
  text-shadow: 0px 4px 0px #ffffff;

  & .pink {
    color: #f43686;
  }
`;

const StyledText = styled('p')`
  margin-top: ${() => (isMobile ? '16px' : '32px')};
  margin-bottom: ${() => (isMobile ? '24px' : '44px')};
  margin-left: 0;
  margin-right: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: ${() => (isMobile ? 'left' : 'center')};
  color: #2d3436;
`;

const StyledButton = styled(Button)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  font-size: ${() => (isMobile ? '20px' : '24px')};
  line-height: 24px;
  border-radius: 100px;
  padding: ${() => (isMobile ? '22px 22px' : '27px 114px')};
  white-space: nowrap;
`;

export default function Footer() {
  const { t } = useTranslation();
  const title = isMobile ? (
    <Markup content={t('shoutout.footer.title.mob')} />
  ) : (
    <Markup content={t('shoutout.footer.title.desk')} />
  );

  const text = t('shoutout.footer.text');
  const btnText = t('shoutout.footer.button');

  return (
    <SectionWrapWithPaddingY>
      <StyledWrap>
        <StyledTitle>{title}</StyledTitle>

        <StyledText>{text}</StyledText>

        <StyledButton
          fullWidth={isMobile}
          variant="contained"
          disableElevation
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}>
          {btnText}
        </StyledButton>
      </StyledWrap>
    </SectionWrapWithPaddingY>
  );
}
