import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import Box from '@mui/material/Box';
import { optimizePicture } from '../../../utils/media';

const StyledUploadAvatarBox = styled(Box)<{
  $transform?: boolean;
  $transformLeft?: boolean;
  $hasImg?: boolean;
}>`
  background-color: ${props =>
    props.$hasImg ? props.theme.colors.white.main : props.theme.colors.pink.light};
  background-position: center;
  background-size: cover;
  border-radius: 0;
  display: block;
  width: ${props => props.theme.spacing(9)};
  height: ${props => props.theme.spacing(9)};
  border: 2px solid white;
  border-radius: 96px;
  padding: 0;
  overflow: hidden;

  ${props =>
    props.$transform &&
    css`
      position: absolute;
      left: 0;
      top: 100%;
      transform: translateY(-50%);
    `}

  ${props =>
    props.$transformLeft &&
    css`
      position: absolute;
      left: ${props => props.theme.spacing(2)};
      top: 100%;
      transform: translateY(-50%);
    `}

  .upload-avatar {
    position: absolute;
    width: auto;
    padding: ${props => props.theme.spacing(2)};
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;

export default function CustomAvatar(props: {
  avatar?: string;
  transform?: boolean;
  transformLeft?: boolean;
  children?: ReactNode;
}) {
  return (
    <StyledUploadAvatarBox
      $hasImg={!!props.avatar}
      $transform={props.transform}
      $transformLeft={props.transformLeft}
      className="avatar"
      style={{
        backgroundImage: `url(${optimizePicture({
          initialUrl: props.avatar!,
          width: 72,
          fit: 'cover'
        })})`
      }}>
      {/* {props.children} */}
    </StyledUploadAvatarBox>
  );
}
