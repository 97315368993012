import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { AdultResourceName } from 'fanpoint-types';
import styled, { css } from 'styled-components';
import { customResourceName } from '../../variables/resource-name';

export const GridItemGrownEllipsis = styled(Grid)<{ $color?: string }>`
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;

  ${props =>
    props.$color &&
    css`
      color: ${props.$color};
    `}
`;

export const AvatarCustom = styled(Avatar)<{
  $size: number;
  $bg?: string;
  $hideFallbackIcon?: boolean;
}>`
  width: ${props => props.$size}px;
  height: ${props => props.$size}px;
  background-color: ${props => props.$bg || props.theme.colors.pink.light};

  ${props =>
    props.$hideFallbackIcon &&
    css`
      & > .MuiAvatar-fallback {
        display: none;
      }
    `}
`;

const StyledMyBox = styled(Box)`
  border: 1px solid ${props => props.theme.colors.purple.light};
  border-radius: ${props => props.theme.spacing(1.5)};
  padding: ${props => props.theme.spacing(1.5)};
  background-color: ${props => props.theme.colors.white.main};
`;

const StyledMyLink = styled(Link)`
  border: 1px solid ${props => props.theme.colors.purple.light};
  border-radius: ${props => props.theme.spacing(1.5)};
  padding: ${props => props.theme.spacing(1.5)};
  user-select: none;
  cursor: pointer;
  color: ${props => props.theme.colors.charcoal.main};
  text-decoration: none;
  text-decoration-color: none;
  display: block;
  background-color: ${props => props.theme.colors.white.main};

  &:hover {
    background-color: #fff5f9;
    border-color: ${props => props.theme.colors.pink.light};

    .title {
      color: #f43686
    }
  }
`;

const StyledTitle = styled('p')`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledName = styled('p')`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2.4rem;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    bottom: 0.6rem;
    right: 0.6rem;
    background-color: ${props => props.theme.colors.white.main};
    padding: 0;
    border-radius: ${props => props.theme.spacing(0.5)};
    overflow: hidden;
  }
`;

export const StyledBadgeImg = styled('img')`
  width: 2rem;
  height: 2rem;
  object-fit: contain;
  object-position: center center;
  display: flex;
`;

export interface IMyLintUITemplate {
  drawAsLink?: boolean;
  type?: 'customLink' | 'credentials';
  title?: string;
  siteName?: string;
  siteUrl?: string;
  img?: string;
  resourceImg?: string;
}

export default function MyLintUITemplate(props: IMyLintUITemplate) {
  const { drawAsLink, title, type, siteName, siteUrl, img, resourceImg } = props;

  const StyledMyLinkProps = {} as any;

  if (siteUrl && drawAsLink) {
    StyledMyLinkProps.component = { Box };
    StyledMyLinkProps.href = siteUrl;
    StyledMyLinkProps.target = '_blank';
  }

  function MyLinkWrap(props: { children: any; siteUrl?: string; drawAsLink?: boolean }) {
    const { children, siteUrl, drawAsLink } = props;

    if (siteUrl && drawAsLink) {
      return (
        <StyledMyLink href={siteUrl} target="_blank" underline="none">
          {children}
        </StyledMyLink>
      );
    }

    return <StyledMyBox>{children}</StyledMyBox>;
  }

  return (
    <MyLinkWrap siteUrl={siteUrl} drawAsLink={drawAsLink}>
      <Grid container spacing={2} alignItems="center" wrap="nowrap">
        <Grid item position="relative">
          {resourceImg ? (
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={<StyledBadgeImg src={resourceImg} loading="eager" />}>
              <AvatarCustom variant="rounded" $size={52} src={img} $hideFallbackIcon={!img} />
            </StyledBadge>
          ) : (
            <AvatarCustom variant="rounded" $size={52} src={img} $hideFallbackIcon={!img} />
          )}
        </Grid>

        <GridItemGrownEllipsis item>
          <StyledTitle className='title'>{title}</StyledTitle>
          <StyledName>
            {type && type === 'credentials' && siteName
              ? customResourceName[siteName as AdultResourceName]
              : siteName}
          </StyledName>
        </GridItemGrownEllipsis>
      </Grid>
    </MyLinkWrap>
  );
}
