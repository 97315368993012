import IconButton from '@mui/material/IconButton';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import bg_desktop from '../assets/bg_desktop.jpg';
import bg_mobile from '../assets/bg_mobile.jpg';
import { Icon as IconifyIcon } from '@iconify/react';

const StyledWrap = styled('div')<{ $isMobile?: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7e797e;
  background-image: url(${props => (props.$isMobile ? bg_mobile : bg_desktop)});
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  z-index: 999999;
`;

const StyledMessage = styled('div')<{ $isMobile?: boolean }>`
  position: relative;
  width: ${props => (props.$isMobile ? 'auto' : '552px')};
  padding: ${props => (props.$isMobile ? '40px' : '80px')} 20px;
  margin: 0 16px;
  background-color: #f43658;
  border-radius: 16px;
  overflow: hidden;
  white-space: pre-wrap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: ${props => (props.$isMobile ? '20px' : '32px')};
  line-height: ${props => (props.$isMobile ? '24px' : '38px')};
  text-align: center;
  color: #ffffff;
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 4px;
`;

export default function MessageError({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  const msg = t('shoutout.msg_error');

  return (
    <StyledWrap $isMobile={isMobile}>
      <StyledMessage $isMobile={isMobile}>
        <StyledIconButton onClick={onClose}>
          <IconifyIcon icon="mdi:close" color="#ffffff" width={24} height={24} />
        </StyledIconButton>
        {msg}
      </StyledMessage>
    </StyledWrap>
  );
}
